/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

@import '../../scss/theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  &.basic-tout__media-wrapper {
    height: auto;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
    }
    &--positioned-stretched {
      position: absolute;
      z-index: auto;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
    &.basic-tout__wrapper {
      display: flex;
      flex-direction: column;
      flex-basis: 0%;
      flex-shrink: 1;
      flex-grow: 1;
      .content-block__line {
        padding-bottom: 0;
      }
    }
    .elc-basic-tout {
      &-content-above-block,
      &-content-below-block {
        flex-basis: 0%;
      }
      &__link {
        .cta-secondary,
        .cta-cover {
          height: auto;
          padding: 0 0 5px 0;
        }
        .cta-secondary--inverted,
        .cta-secondary--inverted-with-arrow,
        .cta-cover--inverted,
        .cta-cover--inverted-with-arrow {
          padding-top: 13px;
          span {
            border-bottom: 1px solid $color--white;
          }
        }
        .cta-secondary--with-arrow,
        .cta-cover--with-arrow {
          height: auto;
          padding: 0 25px 5px 0;
        }
        .cta-secondary--inverted-with-arrow,
        .cta-cover--inverted-with-arrow {
          padding-#{$rdirection}: 30px;
          background-position: 90% 50%;
        }
        .cta-cover--inverted {
          padding-top: 8px;
        }
        .cta-cover--inverted-with-arrow {
          padding: 8px 40px 0 30px;
        }
        .cta-primary {
          &:not(.mantle-cta--custom-textcolor) {
            &:hover,
            &:active,
            &:visited {
              color: $color-cta-black;
            }
          }
        }
        span {
          padding: 0;
        }
      }
    }
  }
  &.elc-basic-tout {
    z-index: 9;
  }
  &__content-below-media {
    padding-top: 20px;
    height: auto;
  }
  &__line {
    // make sure we do not pick up flex alignment rules
    display: block;
    padding-bottom: 20px;
    // compensate for the WYSIWYG adding extra elements
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
    }
    // todo - check this
    &--link {
      padding: 0 12px 12px;
      span {
        padding: 0 20px;
      }
    }
  }
  &__link {
    padding-bottom: 1em;
  }
  &__links {
    display: block;
    min-height: 1.5em;
    padding-bottom: 12px;
    &--2up {
      > div:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  &.basic-tout,
  &.video-tout {
    &__content {
      display: block;
    }
    .content-block__line--link {
      display: inline-block;
    }
  }
  // Text Alignment
  &.text-align--#{$ldirection} {
    text-align: $ldirection;
  }
  &.text-align--center {
    text-align: center;
  }
  &.text-align--#{$rdirection} {
    text-align: $rdirection;
  }
  // mantle text colors
  .text-color--white {
    color: $color--white;
  }
  .text-color--black {
    color: $color--black;
  }
  &-formatter {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__inner {
      width: 100%;
    }
    &__header {
      height: auto;
    }
    &__content {
      height: 100%;
    }
  }
}

.block-nodeblock {
  .grid--mpp__item & {
    height: 100%;
    width: 100%;
    .content {
      height: 100%;
    }
    .node-elc-nodeblock {
      height: 100%;
    }
  }
}

.videojs-video__landing__inner {
  display: flex;
  justify-content: center;
}

.video-paused {
  .videojs-video__landing {
    display: none !important; //hiding the landing on paused to keep consistency on mac videos
  }
}

.videojs-video--loading {
  .videojs-video__player {
    display: none;
  }
  .video-play-trigger {
    border: unset;
    &:before {
      @include spinner;
    }
    &:after {
      content: '';
      background: none;
    }
  }

}
.video-play-trigger {
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: absolute;
  display: flex;
  align-self: center;
  justify-content: center;
  z-index: 1;
  &:after {
    content: '';
    background: url('#{$base-theme-path}svg-icons/src/icon--play-dark.svg') no-repeat;
    width: 60px;
    height: 60px;
    display: flex;
    align-self: center;
    background-size: 100%;
  }
}